





























import {getSize} from '@labor-digital/helferlein/lib/Dom/getSize';
import {isEmpty} from '@labor-digital/helferlein/lib/Types/isEmpty';
import {PageContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/PageContext';
import {AppStoreKeys} from '../../../../../AppStoreKeys';
import Icon from '../../../../Misc/Icon/Icon.vue';

export default {
    name: 'FixedActionButtonSlideInMenu',
    components: {Icon},
    props: {
        context: {
            required: true
        } as PageContext
    },
    data()
    {
        return {
            component: null,
            isOpen: false,
            isAnimated: false,
            displayWidth: 9999
        };
    },
    computed: {
        style()
        {
            return {
                right: -this.displayWidth + 'px'
            };
        },
        classes()
        {
            return {
                'fixedActionButtonSlideInMenu--transition': this.isAnimated,
                'fixedActionButtonSlideInMenu--open': this.isOpen
            };
        }
    },
    methods: {
        onCloseClick()
        {
            this.context.store.set(AppStoreKeys.FIXED_ACTION_BUTTON_MENU_COMPONENT, null);
        },
        onMenuComponentChange(newComponent, old)
        {
            if (newComponent === old) {
                return;
            }
            
            // Handle close
            if (isEmpty(newComponent)) {
                this.isOpen = false;
                this.context.store.set(AppStoreKeys.SIDEBAR_SHOW_OVERLAY, false);
                if (this.component !== null) {
                    setTimeout(() => {
                        this.component = null;
                        this.isAnimated = false;
                        this.$nextTick(() => {
                            this.displayWidth = 9999;
                        });
                    }, 320);
                }
                return;
            }
            
            // Handle open
            // Set component to render
            this.component = newComponent;
            this.$nextTick(() => {
                // Calculate the width
                this.displayWidth = getSize(this.$el).width;
                this.$nextTick(() => {
                    this.$nextTick(() => {
                        // Mark the element as animated
                        this.isAnimated = true;
                        // Open the element
                        this.isOpen = true;
                        this.context.store.set(AppStoreKeys.SIDEBAR_SHOW_OVERLAY, true);
                    });
                });
            });
        }
    },
    mounted(): void
    {
        this.$watch(
            () => this.context.store.get(AppStoreKeys.FIXED_ACTION_BUTTON_MENU_COMPONENT),
            this.onMenuComponentChange
        );
        
        this.$watch(
            () => this.context.store.get(AppStoreKeys.SIDEBAR_SHOW_OVERLAY),
            (n) => {
                if (n === false && this.isOpen) {
                    this.context.store.set(AppStoreKeys.FIXED_ACTION_BUTTON_MENU_COMPONENT, null);
                }
            }
        );
    }
};
