var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "fixedActionButtonSlideInMenu",
      class: _vm.classes,
      style: _vm.style,
    },
    [
      _c(
        "a",
        {
          staticClass: "fixedActionButtonSlideInMenu__closeButton",
          on: { click: _vm.onCloseClick },
        },
        [_c("icon", { attrs: { icon: "close" } })],
        1
      ),
      _vm._v(" "),
      _c(_vm.component, { tag: "component", attrs: { context: _vm.context } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }